.button {
    display: inline-block;
    padding: 0 5px;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 5px
}

/* input[type=number],input[type=text],textarea {
    font-family: Verdana,Helvetica,Arial,Sans-Serif;
    color: #464646;
    padding: 5px 8px;
    vertical-align: middle;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s
} */

.nowrap {
    white-space: nowrap
}

#branah-keyboard {
    line-height: 20px;
    font-size: 1em
}

#branah-keyboard button {
    float: left;
    display: block;
    margin: 1px;
    height: 3em;
    text-align: center;
    color: gray
}

#branah-keyboard .branah-label-natural {
    color: #e0115f
}

#branah-keyboard .branah-label-shift {
    color: #057cb5
}

#branah-keyboard .branah-label-natural,#branah-keyboard .branah-label-shift {
    margin-top: -5px;
    line-height: 20px;
    text-align: center;
    cursor: default
}

#branah-keyboard .branah-label-reference {
    color: gray;
    font-size: .9em;
    line-height: 12px;
    text-align: left;
    cursor: default
}

#branah-k29 .branah-label-reference,#branah-k32 .branah-label-reference {
    color: #000
}

#branah-keyboard .branah-recessed span {
    color: #3C0
}

#branah-keyboard .branah-recessed-hover span {
    color: #ffd800
}

#branah-keyboard .branah-clear {
    clear: both
}
#keyboard #branah-keyboard + p {
    display: none;
}


#branah-keyboard-alpha button[id^='branah'] {
    background-color: #101730;
    border: none;
    outline: none;
    padding: 4px;
    font-size: 0.85rem;
}
#branah-keyboard-alpha button[id^='branah'] * {
    font-size: 0.75rem !important;
    cursor: inherit;
}
#branah-keyboard-alpha button[id^='branah']:hover,
#branah-keyboard-alpha button.branah-recessed {
    background: -webkit-linear-gradient(90deg, #44A0DF -5%, #456EC8 100%);
    background: linear-gradient(90deg, #44A0DF -5%, #456EC8 100%);
    color: #ffffff !important;
}
#branah-keyboard-alpha button[id^='branah']:hover *,
#branah-keyboard-alpha button.branah-recessed * {
    color: #ffffff !important;
}
textarea#editor {
    height: 100%;
    width: calc(100% - 1em);
    background-color: transparent;
    background-image: none;
    border: none;
    outline: none;
    color: #d0d0d0;
    padding: 1em !important;
    resize: none;
    font-size: 16px;
}

.inpTrans > input#editor {
    height:24px;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    color: #d0d0d0;
}



@media only screen and (max-width: 640px) {    

    #branah-keyboard button {
        width: 88px
    }

    #branah-keyboard .branah-key {
        width: 43px
    }

    .gsc-search-box,.gsc-control-cse {
        display: none
    }
    #branah-keyboard {
        max-height: 236px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

@media only screen and (min-width: 641px) and (max-width:840px) {
    

    #editor {
        width: 600px
    }

    #keyboard {
        width: 630px
    }

    #selectAll,#saveAsTextFile,#postToFacebook,#shrink,#enlarge {
        margin-right: 2px;
        padding: 0 5px;
        vertical-align: middle;
        line-height: 18px
    }
}

@media only screen and (min-width: 841px) {    

    #editor {
        width: 607px;
        height: 96px
    }
}

@media only screen and (min-width: 641px) {
    #branah-keyboard {
        width:630px;
        margin: 0 auto;
    }

    #branah-keyboard button {
        line-height: 2.75em
    }

    #branah-keyboard .branah-key {
        width: 40px
    }

    #branah-backspace {
        width: 78px
    }

    #branah-tab {
        width: 62px
    }

    #branah-k25 {
        width: 56px
    }

    #branah-caps-lock {
        width: 76px
    }

    #branah-enter {
        width: 84px
    }

    #branah-left-shift {
        width: 46px
    }

    #branah-right-shift {
        width: 114px
    }

    #branah-space {
        width: 246px;
        text-align: center
    }

    #branah-right-ctrl,#branah-right-alt,#branah-escape {
        width: 62px
    }

    #branah-left-ctrl,#branah-left-alt,#branah {
        width: 60px
    }
}
