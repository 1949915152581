/* Common Modules Stylesheet */

.pagecontainer {
    max-width: 1310px;
    margin: 0 auto;
    padding-top: 1.25rem;
}
.pagecontainerXXL {
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 1.25rem;
}
.pxC {
    padding-left: 1rem;
    padding-right: 1rem;
}

.dropbox {
    position: relative;
    width: 250px;
}
.dropbox button {
    display: block;
    width: 100%;
    background-image: none;
    border: solid 1px #444A5F;
    background-color: #101730;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    position: relative;
    padding: 8px 30px 8px 12px;
    font-size: 14px;
    color: #d0d0d0;
    text-align: left;
}
.dropbox button > svg {
    position: absolute;
    right: 6px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.dropbox .dropped {
    position: absolute;
    z-index: 1;
    top: 110%;
    left: 0;
    width: 100%;
    background-image: none;
    border: solid 1px #444A5F;
    background-color: #101730;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    color: #d0d0d0;
    overflow: hidden;
}
.dropbox .dropped .ddItem {    
    position: relative;
    border-bottom: solid 1px rgba(68, 74, 95, 1);
}
.dropbox .dropped .ddItem:last-of-type {
    border: none;
}
.dropbox .dropped .ddItem label {
    font-size: 13px;
    font-weight: 400;
    padding: 8px 32px 8px 12px;
    display: block;
}
.dropbox .dropped .ddItem > svg {
    position: absolute;
    right: 6px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 16px;
}
.dropbox .dropped .ddItem > svg.chkd {
    display: none;
}
.dropbox .dropped .ddItem > input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.dropbox .dropped .ddItem > input:checked ~ svg.nonChk {
    display: none;
}
.dropbox .dropped .ddItem > input:checked ~ svg.chkd {
    display: block;
}
.dropbox .dropped .ddItem:hover label {
    background-color: rgba(18, 50, 100, 0.4);
}
.dropbox .dropped .ddItem > input:checked ~ label {
    background-color: rgba(18, 50, 100, 1);
}

.selOptBox {
    max-width: 920px;
    margin: 0 auto;
    text-align: center;
    padding: 1em;
    background-color: #101730;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}
.selOptBox ul li {
    display: inline-block;        
    margin: 10px;
}
.selOptBox ul li a,
.selOptBox ul li button {
    display: block;
    padding: 8px 20px;
    border: solid 1px #444A5F;
    color: #d0d0d0;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    text-decoration: none;
}
.selOptBox ul li a:hover,
.selOptBox ul li button:hover {
    border-color: #ffffff;
    color: #ffffff;
}

.transBox {
    height: 700px;
    background-color: #1B223B;
    -webkit-border-radius: 0.75rem;
    border-radius: 0.75rem;
    overflow: hidden;
}
.transBox .scndKBBox {
    padding: 0.8em 0 0.3em;
    border-top: solid 1px #444A5F;
}
.transBox .rightSBox {
    background-color: #101730;
    border-left: solid 1px #444A5F;
    color: #d0d0d0;
}
.textAreaPne {
    position: relative;
    padding-bottom: 60px;
    white-space: normal;
    word-wrap: break-word;
}
.textAreaPne.pb0 {
    padding-bottom: 0;
}
.textAreaPne .btnHldr {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.textAreaPne textarea,
.textAreaPne textarea#editor {
    height: 100%;
    width: 100%;
    background-color: transparent;
    background-image: none;
    border: none;
    outline: none;
    color: #d0d0d0;
    padding: 1em;
    resize: none;
}
.kbtoggleBtn {
    background-color: transparent;
    background-image: none;
    border: none;
    outline: none;
    color: #d0d0d0; 
    font-size: 42px;
    line-height: 1;
}
.rightSBox .textAreaPne {
    padding-bottom: 40px;
}
.optActBtn {
    background-color: transparent;
    background-image: none;
    border: none;
    outline: none;
    color: #d0d0d0; 
    font-size: 26px;
    line-height: 1;
}

.blkBdrIcoBtn {
    display: inline-block;
    background-image: none;
    border: solid 1px #444A5F;
    background-color: #101730;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    position: relative;
    padding: 8px 12px 8px 38px;
    font-size: 14px;
    color: #d0d0d0;
}
.blkBdrIcoBtn:hover {
    background-color: #444A5F;
}

.blkBdrIcoBtn.edit::before {
    content: '';
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-image: url('../../../public/svg/edit-c-ico.svg');
    background-size: contain;
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.navyBox {
    background-color: #101730;
    color: #d0d0d0;
}

.cDDBoxNonBdr .dropbox {
    width: 100%;
}
.cDDBoxNonBdr .dropbox > button {
    background-color: transparent;
    border-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
}
.cDDBoxNonBdr .dropbox .ddItem a {
    display: block;
}
.cDDBoxNonBdr .dropbox .ddItem a:hover {
    background-color: #444A5F;
}
.cDDBoxNonBdr .dropbox button > svg {
    right: -6px;
}
.cDDBoxNonBdr .dropbox .dropped {
    top: calc(100% + 8px);
}

.fltRow {
    border-bottom: solid 1px #444A5F;
    position: relative;
}
.fltRow:last-of-type {
    border-bottom: none;
}
.fltRow::before {
    content: '';
    width: 0.5px;
    height: 100%;
    background-color: #000000;
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.statTbl {
    color: #d0d0d0;
    padding-bottom: 8px;
}
.statTbl .statTBRow {
    border: solid 1px transparent;
    background-color: #272E47;
    margin-bottom: 2px;    
}
.statTbl .tBodyArea .statTBRow > div,
.recordsBtns > div {
    position: relative;
    min-height: 4.2rem;
}
.statTbl .tBodyArea .statTBRow > div:not(:last-of-type)::after,
.recordsBtns > div:not(:last-of-type)::after {
    content: '';
    width: 0.5px;
    height: 100%;
    background-color: #000000;
    position: absolute;
    right: 0;
    top: 0;
}
.statTbl .statTBRow:last-of-type {
    margin-bottom: 0;
}
.statTbl .statTBRow:hover,
.statTbl .statTBRow.active {
    background-color: #243B5C;
    border-color: #44A0DF;
}
.statTbl .statTBRow > div {
    padding: 4px 8px;
}
.statTbl .tBodyArea {
    height: 375px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
p.capt {
    color: rgba(99, 99, 99, 1);
    font-size: 9px;
}
.recordsBtns,
.playBtnsBx {
    background-color: #272E47;    
}
.recordsBtns button.recBtn {
    font-size: 12px;
    cursor: pointer;
    padding: 0.75rem;
}
.recordsBtns button.recOthBtn {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);    
}
.recordsBtns button.recOthBtn:hover,
.recordsBtns button.recOthBtn.active {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 1 !important;
}
.recordsBtns button.recOthBtn.undo {
    background-image: url('../../../public/svg/undo-recording-blue-ico.svg');
    opacity: 1;
}
.recordsBtns button.recOthBtn.delete {
    background-image: url('../../../public/svg/bin-blue-icon.svg');
    opacity: 0.8;
}
.recordsBtns button.recOthBtn.save {
    background-image: url('../../../public/svg/save_icon.svg');
    opacity: 1;
    background-size: 40% auto;
}

.recordsBtns button.recOthBtn:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.8 !important;
}

button.playBtn,
button.pauseBtn {
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat; 
    width: 35px;
    height: 35px;    
}
button.playBtn {   
    background-image: url('../../../public/svg/play-blue-icon.svg');
}
button.pauseBtn {       
    background-image: url('../../../public/svg/pause_circle_icon.svg');
}

.nonSelectedStatusBox {
    height: 368px;
    background-color: #272E47;
    font-size: 16px;
    color: #1B223B;
    font-weight: bold;
}
.nonSelectedTAreaBox {
    color: #272E47;
    font-size: 16px;
    font-weight: bold;
}
.nonPunjabiBtnHldr {
    position: absolute;
    bottom: 1em;
    left: 1em;
}

.uplBox strong {
    font-weight: 600;
}
.uplBox .browseIn {
    display: inline-block;
    position: relative;
    padding: 0 5px;
    text-decoration: underline;    
    color: #44A0DF;
}
.uplBox .browseIn > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: inherit;
}
.muteTxt {
    color: #707070;
    font-size: 0.65rem;
}

h2.contTitle {
    font-size: 1rem;
    text-align: center;
    padding: 14px 12px;
    border-bottom: solid 1px rgba(55, 60, 77, 0.60);
    color: #d0d0d0;
    font-weight: 500;
}
.bdrFields input,
.bdrFields select {
    display: block;
    width: 100%;
    height: 46px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    border: 1px solid #444A5F;
    background: #101730;    
}
.bdrFields input {
    padding: 6px 12px;
}
.bdrFields select {
    background-image: url('../../../public/svg/fld-select-arrow.svg');    
    padding: 6px 2.25rem 6px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 11px 5px;
}
.bdrFields div > label {
    font-size: 0.75rem;
    color: #d0d0d0;
}
.bdrFields div > label > span {
    font-size: 0.75rem;
    font-family: inherit;
}

.dictPageWrap {
    max-width: 690px;
    margin: 0 auto;
}
.deepNavyBox {
    background-color: #1B223B;
    -webkit-border-radius: 0.75rem;
    border-radius: 0.75rem;
    color: #d0d0d0;
}
.dicTxtFld {
    border: solid 1px transparent;
}
.dicTxtFld:hover,
.dicTxtFld:focus {
    border-color: #44A0DF;
}
.tmuted {
    color: #d0d0d0;
}
.keyBoardFloat {
    position: fixed;
    width: 100%;
    left: 50%;
    bottom: 0;
    z-index: 9;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #38405B;
    padding: 1em 0 0;
    -webkit-box-shadow: 0 0 8px #000000;
    box-shadow: 0 0 8px #000000;
}


.uploadBtn,
.downloadBtn {
    background-image: none;
    background-color: rgba(68,160,223,0.2);
    display: inline-block;
    vertical-align: middle;
    outline: none;
    border: solid 1px #44A0DF; 
    color: #ffffff;   
    -webkit-border-radius: 6px;
    border-radius: 6px;
    padding: 6px 12px;
    line-height: 1.9;
    cursor: pointer;
    position: relative;
}
.uploadBtn:hover,
.downloadBtn:hover {
    background-color: #44A0DF;
}
.uploadBtn > input {
    margin: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}
.uploadBtn span,
.downloadBtn span {
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;    
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: contain;   
    -webkit-filter: brightness(100); 
    filter: brightness(1) invert(1); 
    margin-right: 6px;
    cursor: inherit;
}
.uploadBtn span {
    background-image: url('../../../public/svg/upload_arrow-icon.svg');
}
.downloadBtn span {
    background-image: url('../../../public/svg/download_arrow_dn.svg');
}

.areaS {
    min-height: 200px;
}
.areaS .wrapTxt {
    font-size: 10px;
    color: blue;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
}
.areaS .wrapTxt .wtRemove {
    width: 14px;
    height: 14px;
    background-color: #ffffff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: none;
    outline: none;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../public/svg/close_circled_red_icon.svg');
    position: absolute;
    top: -10px;
    left: 3px;
    z-index: 2;
}
.areaS table {
    display: none !important;
}

.imgSizeFlex {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 140px);
}

.imgSizeFlex > img {
    margin-top: -2.2rem;
}

.imgSizeFlex .downloadBtnWrap {
    position: sticky;
    display: block;
    text-align: right;
    position: sticky;
    top: 1rem;
    padding: 0 1rem 0 0;
}
.imgSizeFlex button.downloadBtn {
    background-color: #44A0DF;
    -webkit-box-shadow: 0 0 6px rgba(0,0,0,0.6);
    box-shadow: 0 0 6px rgba(0,0,0,0.6);
}

.arNo {
    background-color: #44A0DF;
    display: inline-block;
    color: #ffffff;
    padding: 1px 4px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    height: 14px;
    line-height: 12px;
    text-align: center;
    -webkit-transform: translate(22px, -8px);
    transform: translate(22px, -8px);
}

.arNoHB {
    position: absolute;
    left: -2px;
    z-index: 4;
    top: -6px;
    background-color: #44A0DF;
    display: inline-block;
    color: #ffffff;
    padding: 1px 4px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    height: 14px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 12px;
    font-size: 8px;
}

.statLbl {
    display: inline-block;
    color: #ffffff;
    padding: 2px 12px;
    font-size: 12px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    color: #ffffff;
    text-transform: capitalize;
}

.statLbl[class*='ccept'],
.statLbl[class*='view'],
.statLbl[class*='load'] {
    background-color: #44A0DF;
}
.statLbl[class*='eje'] {
    background-color: #e46060;
}
.statLbl[class*='end'],
.statLbl[class*='cess'] {
    background-color: #B6BA04;
}

.areaS.tagDisabled {
    position: relative;
    z-index: 8;
}
.areaS.tagDisabled::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
}

.darkModalBG {
    background: #38405B;
}

.epfld > label {
    font-weight: 500;
    display: block;
    padding: 0 12px 3px;
    color: #f2f2f2;
}
.epfld input,
.epfld select,
.epfld textarea {
    display: block;
    width: 100%;    
    border-radius: 7px;
    border: 0.716px solid #444A5F;
    background: #272E47;
    color: #ffffff;
    padding: 8px 12px;
}
.epfld input,
.epfld select {
    height: 42px;
}
.epfld select {
    background-image: url('../../../public/svg/fld-select-arrow.svg');    
    padding-right: 2.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 11px 5px;
}

.epfld input:-internal-autofill-selected {
    background: #272E47 !important;
}


@media all and (min-width: 1600px) {
    .pagecontainer {
        max-width: 1480px;
    }
    .pagecontainerXXL {
        max-width: 1580px;
    }
}

@media all and (max-width: 991px) {
    .transBox {
        height: 902px;
    }
}


