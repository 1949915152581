@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,800;1,400;1,600;1,800&display=swap');

:root {
    --primary: #4498DB;
    --secondary: #4B5472;
    --primary2: #44A0DF;
    --bdrGray: #444A5F;
    --scrollbar-foreground: #293866;
    --scrollbar-background: #4B5472;
    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 8px; 
    height: 8px;
}
::-webkit-scrollbar-thumb { 
    background: var(--scrollbar-foreground);
}
::-webkit-scrollbar-track { 
    background: var(--scrollbar-background);
}

body {    
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
}

body h1 {
    font-weight: 800;
}
body h2, body h3, body h4, body h5, body h6, body strong {
    font-weight: 600;    
}

button.btnPrimary,
a.btnPrimary {
    display: inline-block;
    background: -webkit-linear-gradient(90deg, #44A0DF -3.28%, #456EC8 107.55%);
    background: linear-gradient(90deg, #44A0DF -3.28%, #456EC8 107.55%);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    outline: none;
}
button.btnPrimary:hover,
a.btnPrimary:hover {
    background: -webkit-linear-gradient(90deg, #456EC8 -3.28%, #44A0DF 107.55%);
    background: linear-gradient(90deg, #456EC8 -3.28%, #44A0DF 107.55%);
}

button.btnOutline,
a.btnOutline {
    display: inline-block;
    background-color: transparent;
    background-image: none;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    outline: none;
    border-style: solid;
    border-width: 1px;
    border-color: var(--primary);
    text-align: center;
}
button.btnOutline:hover,
a.btnOutline:hover {
    background-color: var(--primary);
    color: #ffffff;
}

button.btnTxt,
a.btnTxt {
    display: inline-block;
    background-color: transparent;
    background-image: none;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    outline: none;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    color: var(--primary);
    text-align: center;
}
button.btnTxt:hover,
a.btnTxt:hover {
    color: #ffffff;
}

button.btnPrimary:disabled,
button.btnOutline:disabled {
    -webkit-filter: grayscale(1) brightness(1);
    filter: grayscale(1) brightness(1);
    pointer-events: none;
    opacity: 0.85;
}
button.btnPrimary:disabled {
    -webkit-filter: grayscale(1) brightness(1) invert(1);
    filter: grayscale(1) brightness(1) invert(1);
}


i.gIco {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../public/g-ico.svg');
}

.orSptr {
    color: var(--secondary);
    position: relative;
    margin: 2em 0;
    text-align: center;
}
.orSptr::before,
.orSptr::after {
    content: '';
    width: 42%;
    height: 1px;
    background-color: var(--secondary);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);    
}
.orSptr::before {
    left: 0;
}
.orSptr::after {
    right: 0;
}

body .text-primary {
    color: var(--primary);
}

.inpFld {
    padding: 0;
    margin-bottom: 0.5em;
    height: 48px;
    background-color: transparent;
    border-style: solid;
    border-width: 0 0 1px;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-color: var(--primary);
    font-size: 14px;
    outline: none;    
}
.inpFld.error {
    border-color: #d16c6c;
}
.inpFld:focus {
    border-bottom-width: 2px;
    border-color: #ffffff;
}
.inpFld::-webkit-input-placeholder { 
    color: var(--secondary);
}
.inpFld::-moz-placeholder { 
    color: var(--secondary);
}
.inpFld:-ms-input-placeholder { 
    color: var(--secondary);
}
.inpFld:-moz-placeholder { 
    color: var(--secondary);
}
.inpFld.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pageWrap .CircularProgressbar .CircularProgressbar-trail {
    stroke: #585858;
}
.pageWrap .CircularProgressbar-text {
    fill: #44A0DF;
    font-weight: bold;
}

.actions {
    padding-left: 50px;
    padding-right: 50px;    
}
.actions .slick-slider {
    min-height: 112px;
}
.actions .slick-slider .slick-arrow {
    width: 50px;
    height: 100%;
    background-color: #101730;
    -webkit-border-radius: 0.75rem;
    border-radius: 0.75rem;    
}
.actions .slick-slider .slick-arrow.slick-prev {
    left: -50px;    
}
.actions .slick-slider .slick-arrow.slick-next {
    right: -50px;
}
.actions .slick-slider .slick-arrow::before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../public/svg/slider-left-arrow-ico.svg');
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.actions .slick-slider .slick-arrow.slick-next::before {
    -webkit-transform: translate(-50%, -50%) scale(-1);
    transform: translate(-50%, -50%) scale(-1);
}

.pageWrap .MuiSlider-mark {
    display: none;
}
.pageWrap .MuiSlider-markLabel {
    font-size: 10px;
    color: #d0d0d0;
}
.pageWrap .MuiSlider-root.MuiSlider-marked.MuiSlider-colorPrimary {
    color: #ffffff;
    margin-bottom: 12px;
}



.relative button.toggleBtn {
    width: 3.2em;
    text-align: right;
    background-color: transparent;
    background-image: none;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    font-size: 12px;
    position: absolute;
    z-index: 9;
    top: 36px;
    right: 0;
    cursor: pointer;
}

.relative .inpFld {
    padding-right: 3em;
}

.error {
    color: #d16c6c;
}


.flex.p-4 .flex-1 textarea#editor {
    padding: 0 1rem 0 0 !important;  
    height: 82%;
} 
.flex.p-4.tGrids .flex-1 textarea#editor {
    width: 95%;
    height: 82%;
}


.grayBox .react-tabs__tab-list {
    border-bottom-color: #000000;
}
.grayBox .react-tabs__tab {
    border-width: 0 1px 0 0;
    border-color: #000000;
    background-color: transparent;
    color: #ffffff;
    padding: 16px 24px;
    -webkit-border-radius: 0;
    border-radius: 0;
    bottom: 0;
}
.grayBox .react-tabs__tab:focus::after {
    display: none;
}
.grayBox .react-tabs__tab--selected {
    background-color: rgba(68, 160, 223, 0.1);
}
.grayBox .react-tabs__tab span {
    display: block;
    text-align: center;
}
.grayBox .react-tabs__tab--selected span {
    background: linear-gradient(90deg, #44A0DF -3.28%, #456EC8 107.55%), #D0D0D0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: bold; 
}

.highBG > pre,
.grayBG {
    background-color: #272E47 !important;
    -webkit-box-shadow: 0 0 0 transparent !important;
    box-shadow: 0 0 0 transparent !important;
    border: none !important;
    margin: 0 0 1.5em;
    -webkit-border-radius: 12px;
    border-radius: 12px;
}

button.btnOutlineGray,
a.btnOutlineGray {
    display: inline-block;
    background-color: transparent;
    background-image: none;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    outline: none;
    border-style: solid;
    border-width: 1px;
    border-color: var(--bdrGray);
    text-align: center;
}
button.btnOutlineGray:hover,
a.btnOutlineGray:hover {
    background-color: var(--bdrGray);
    color: #ffffff;
}

.grayBox audio::-webkit-media-controls-panel {
    background-color: #44A0DF;
}

.App .highcharts-container,
.App .highcharts-container .highcharts-root {
    max-width: 100%;
}
.App .highcharts-container .highcharts-axis-labels text {
    fill: #f1f1f1 !important;
}
.App .highcharts-container .highcharts-grid-line {
    stroke: #484E64 !important;
}
.App .highcharts-container .highcharts-axis.highcharts-yaxis text {
    fill: #f1f1f1 !important;
}

.grayBox .CodeMirror {
    background-color: #161616;
    color: #ffffff;
    min-height: 200px;
    height: calc(100vh - 260px);
}
.grayBox .CodeMirror-gutters {
    background-color: #313239;
    border-right: 1px solid #463c3c;
}
.grayBox .CodeMirror-cursor {
    border-left-color: #ffffff;
}

body .authModBox input:-internal-autofill-selected {
    background-color: rgba(70, 90, 126, 0.4) !important;
}

.react-tags {
    width: 100%;
    border-radius: 7px;
    border: 0.716px solid #444A5F;
    background: #272E47;
    color: #ffffff;
    padding: 6px 12px;
    position: relative;    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 42px;
}
.react-tags__tag:after {
    content: "\2715";
    color: #aaa;
    margin-left: 6px;
    font-size: 0.75rem;
}

.react-tags .react-tags__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.react-tags__combobox .react-tags__combobox-input {
    background-color: transparent;
    border: none;
    outline: none;
    color: #c2c2c2;
    padding: 1px 0;
    font-size: 0.75rem;
}

.react-tags .react-tags__list-item {
    margin: 3px 12px 3px 0;
    padding: 2px 4px;
    border: solid 1px rgba(255,255,255,0.3);
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.react-tags .react-tags__listbox {
    background-color: var(--primary);
    font-size: 0.75rem;
    text-align: center;
    padding: 2px 4px;
    display: block;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);    
}

/* MUI Acc */
.pageWrap div[class*='MuiTypography-root'] {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
}
.pageWrap div[class*='MuiAccordion-root'] {
    background-color: transparent;
    border: none;
    color: #ffffff;
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
}
.pageWrap div[class*='MuiAccordionSummary-root'],
.pageWrap div[class*='MuiAccordionSummary-content'] {
    padding: 0;
    margin: 0;
    min-height: 0;
}
.pageWrap div[class*='MuiAccordionSummary-root'] {
    margin-bottom: 12px;
}
.pageWrap div[class*='MuiAccordionDetails-root'] {
    padding: 0;
}
.pageWrap div[class*='MuiAccordionSummary-expandIconWrapper'] {
    color: #f2f2f2;
}

table th.text-left,
table td.text-left {
    text-align: left !important;
    font-size: 13px !important;
    padding: 6px 12px !important;
}