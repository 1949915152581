:root {
    --primary: #4498DB;
    --secondary: #4B5472;
    --primary2: #44A0DF;
    --authBG: #03103C;
    --bdrGray: #444A5F;
    --darkBG: #101730;
    --dark2: #1B223B;
    --dark3: #272E47;
    --grayTxt: #9F9F9F;
    --prime1: #44A0DF;
    --prime2: #456EC8;
    --error: #e36060;
}

.darkBG {
    background-color: var(--darkBG);
    color: #ffffff;
}

.catFrontLWrap {
    max-height: calc(100vh - 240px);
    overflow-x: hidden;
    overflow-y: auto;
}
.lBox {
    background-color: var(--dark2);
}
.lBox div[class^='px'] button:hover {
    color: var(--primary);
}

.listLBL {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.catListDropBox,
.cListWrapL .lBox {
    background-color: var(--dark3);
}

.lFile {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 16px;
    border-bottom: solid 0.5px rgba(255,255,255,0.1);
    font-size: 0.85rem;
}
.lFile:last-of-type {
    border-bottom: none;
}

.ctPanelWrap {
    background-color: var(--dark2);
    min-height: calc(100vh - 114px);
}
.ctPanelWrap > div:last-of-type {
    background-color: var(--dark3);
}

.cListWrapL {
    max-height: calc(100vh - 200px);
    overflow-x: hidden;
    overflow-y: auto;
}

.lBox.lBxClick {
    border: solid 1px transparent;
    cursor: pointer;
}
.lBox.lBxClick label {
    cursor: inherit;
}
.lBox.lBxClick:hover,
.lBox.lBxClick.active {
    border-color: #ffffff;
    background-color: var(--darkBG);
}

input.listLBL {
    padding: 6px 12px;
    background-color: transparent;
    border: solid 2px transparent;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}
input.listLBL:not(:disabled) {    
    border-color: var(--bdrGray);
    opacity: 1;
}
input.listLBL:disabled {
    border-color: transparent;
    opacity: 0.85;
}
