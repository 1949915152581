:root {
    --primary: #4498DB;
    --secondary: #4B5472;
    --primary2: #44A0DF;
    --authBG: #03103C;
    --bdrGray: #444A5F;
    --darkBG: #101730;
    --grayTxt: #9F9F9F;
    --prime1: #44A0DF;
    --prime2: #456EC8;
    --error: #e36060;
}

.topProfBox,
.darkBG {
    background-color: var(--darkBG);
    color: #ffffff;
}


.profIco,
.profLetter {
    width: 85px;
    height: 85px;
    background-color: #5c91bd;
    border: solid 2px #ffffff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
}
.profIco {   
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    background-image: url('../../../public/svg/default-prof-ico.svg');  
}
.profLetter > span {
   font-weight: 600;
   font-size: 2.2rem;
   color: #ffffff; 
   height: 100%;
   line-height: 82px;
   text-transform: uppercase;
}

.gray {
    color: var(--grayTxt);
}
.txtPrime {
    color: var(--primary);
}
.dataGrid {
    max-width: 380px;
    border-top: solid 1px rgba(208,208,208,0.29);
}
.dataGrid > div:nth-of-type(2) {
    border-left: solid 1px rgba(208,208,208,0.29);
    border-right: solid 1px rgba(208,208,208,0.29);
}
.dataGrid > div {
    padding: 14px;
    line-height: 1.3;
}
.dataGrid strong {
    font-size: 1.1rem;
}

.gradBG {
    background: linear-gradient(90deg, var(--prime1) -3.28%, var(--prime2) 107.55%);
}

.whiteBx {
    background-color: #ffffff;
    color: var(--primary);
}

.tagsTableWrap table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}
.tagsTableWrap table th,
.tagsTableWrap table td {
    border: solid 0.5px rgba(255,255,255,0.13);
    padding: 10px 14px;
    text-align: center;
    font-size: 14px;
    line-height: 1.3;
}
.tagsTableWrap table th > span {
    display: block;
    font-weight: normal;
    font-size: 96%;
}
.tagsTableWrap table th:first-of-type,
.tagsTableWrap table td:first-of-type {
    border-left-width: 0;
}
.tagsTableWrap table th:last-of-type,
.tagsTableWrap table td:last-of-type {
    border-right-width: 0;
}
.tagsTableWrap table thead > tr:first-of-type > th {
    border-top-width: 0;
}
.tagsTableWrap table tbody > tr:last-of-type > td {
    border-bottom-width: 0;
}

.tagsTableWrap table.tagsTableBdr th:first-of-type,
.tagsTableWrap table.tagsTableBdr td:first-of-type {
    border-left-width: 0.5px;
}
.tagsTableWrap table.tagsTableBdr th:last-of-type,
.tagsTableWrap table.tagsTableBdr td:last-of-type {
    border-right-width: 0.5px;
}
.tagsTableWrap table.tagsTableBdr thead > tr:first-of-type > th {
    border-top-width: 0.5px;
}
.tagsTableWrap table.tagsTableBdr tbody > tr:last-of-type > td {
    border-bottom-width: 0.5px;
}

.tagsTableWrap table tr.accepted td {
    color: #4499DC;
}
.tagsTableWrap table tr.rejected td {
    color: #CA3559;
}
.tagsTableWrap table tr.pending td {
    color: #B6BA04;
}
.tagsTableWrap table td img {
    display: inline-block;
}

.eFormWrap {
    max-width: 494px;
}
button.addThumb {
    width: 26px;
    height: 26px;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    background-image: url('../../../public/svg/upload-img-prof.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: -7px;
    bottom: 2px;
    z-index: 2;
}
button.addThumb > input {
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
    opacity: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
}

.epfld > label {
    font-weight: 500;
    display: block;
    padding: 0 12px 3px;
    color: #f2f2f2;
}
.epfld input,
.epfld select,
.epfld textarea {
    display: block;
    width: 100%;    
    border-radius: 7px;
    border: 0.716px solid #444A5F;
    background: #272E47;
    color: #ffffff;
    padding: 8px 12px;
}
.epfld input,
.epfld select {
    height: 42px;
}
.epfld select {
    background-image: url('../../../public/svg/fld-select-arrow.svg');    
    padding-right: 2.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 11px 5px;
}

.epfld input:-internal-autofill-selected {
    background: #272E47 !important;
}


div.error {
    color: var(--error);
    font-size: 0.75rem;
    padding-left: 14px;
}

.epfld input:disabled,
.epfld select:disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.45;
}