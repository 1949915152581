:root {
    --primary: #4498DB;
    --secondary: #4B5472;
    --primary2: #44A0DF;
    --authBG: #03103C;
    --error: #e36060;
    --tagBG: #009285;
}

.guideBox {
    background-color: var(--authBG);    
    box-shadow: -10px 0 12px rgba(0, 0, 0, 0.3);
}
.guideBox .cancelBtn {
    position: absolute;
    left: -40px;
    top: 10px;
    z-index: 9;
}
.guideInner {
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
}
.tagLbl {
    background-color: var(--tagBG);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 4px 12px;
    color: #ffffff;
    font-size: 0.80rem;
    font-weight: 600;
}



@media all and (max-width: 1024px) {
    .guideBox .cancelBtn {
        left: 10px;
        top: 10px;
    }
}