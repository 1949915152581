/* Stylesheet for Auth pages */

:root {
    --authBG: #03103C;
}

html > body {
    background-color: var(--authBG);
    color: #d0d0d0;
}

.authWrapper .sideAuthBox {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../../public/auth/auth-art-box.svg');
    position: relative;
}
.sideAuthBox .captionBox {
    background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 0px 5px 5px 0px;
    position: absolute;
    left: 0;
    top: 3rem;
    z-index: 1;
    color: #d0d0d0;
    padding: 1.5em 1.5rem 1.5rem 4rem;
    font-size: 10px;
    font-weight: 400;
}
.sideAuthBox .captionBox h1 {
    color: #449CDD;  
}
.sideAuthBox .captionBox::before {
    content: '';
    width: 2.3rem;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-background: linear-gradient(175.13deg, #44A0DF -5%, #456EC8 100%);
    background: linear-gradient(175.13deg, #44A0DF -5%, #456EC8 100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.authModBox a {
    color: inherit;
}

body .inpFld {
    background-color: var(--authBG);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
}

#signInDiv {
    max-width: 348px;
    margin: 0 auto;
}



@media all and (max-width: 767px) {    
    .sideAuthBox .captionBox {
        top: 1.2rem;
        padding: 0.6em 0.6rem 0.6rem 2rem;
        font-size: 9px;
    }
    .sideAuthBox .captionBox::before {
        width: 1.4rem;
    }    
}

@media all and (min-width: 768px) {
    .copyTxt {
        position: absolute;
        z-index: 9;
        right: 1em;
        bottom: 0.5em;
    }
    .authModBox {
        margin: auto;
        max-height: 74vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 2em;
    }
}