/* Style for all the pages */

:root {
    --primary: #4498DB;
    --secondary: #4B5472;
    --primary2: #44A0DF;
    --authBG: #03103C;
    --bdrGray: #444A5F;
}

html > body {
    position: relative;
}
.pageWrap {
    background-color: #101730;
    color: #ffffff;
	min-height: 100vh;
}

.mainHdr {
    background-color: var(--authBG);
    position: sticky;
    z-index: 9;
    left: 0;
    top: 0;
}

.App .mainHdr + .pageWrap {
    min-height: calc(100vh - 62px);
}

.mainHdr .nav a {
    display: block;
    padding: 12px;
    text-align: left;
    background-color: transparent;
    border-style: solid;
    border-width: 0 3px 0 0;
    border-color: transparent;
}

.mainHdr .nav li.active a {
    background-color: rgba(68, 160, 223, 0.24);
    border-color: #44A0DF;
    color: var(--primary2);
    font-weight: 600;
}

a.pbrand {
    position: relative;
    display: block;
    padding-left: 44px;
}
a.pbrand::before {
    content: '';
    width: 64px;
    height: 60px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../public/svg/logo-ico.svg');
    position: absolute;
    left: 0;
    top: -14px;
}
a.pbrand h2,
.text-gradient {
    background: linear-gradient(90deg, #44A0DF -3.28%, #456EC8 107.55%), #D0D0D0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;    
}
a.pbrand h2 {
    transform: translateY(6px);
}

.dashTopPanel,
.bgLight {
    background-color: #1A2139;
}

.counterBox {
    background: linear-gradient(90deg, #44A0DF -3.28%, #456EC8 107.55%), #D0D0D0; 
    color: #ffffff;
    height: 200px;
}
.counterBox .cIco {
    display: inline-block;
    width: 42px;
    height: 42px;
    background-color: rgba(255,255,255,0.3);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50% auto;  
    -webkit-border-radius: 10px;
    border-radius: 10px;  
}
.counterBox .cIco.users {
    background-image: url('../../../public/svg/users-ico.svg');
    background-size: 60% auto;
}
.counterBox .cIco.cate {
    background-image: url('../../../public/svg/cate-ico.svg');
}
.counterBox .cIco.subcate {
    background-image: url('../../../public/svg/subcate-ico.svg');
}
.counterBox .cIco.images {
    background-image: url('../../../public/svg/image-ico.svg');
    background-size: 76% auto;
}
.counterBox .cIco.imageDoc {
    background-image: url('../../../public/svg/vscode-icons_folder-type-images-opened.svg');
    background-size: 76% auto;
}
.counterBox > div {
    border-right: solid 1px rgba(0,0,0,0.15);
}
.counterBox > div:last-of-type {
    border-right: none;
}
.counterBox h3 {
    font-size: 1.5em;
}


body .highcharts-background {
    fill: none;
}
body .highcharts-legend-item text {
    fill: #f1f1f1 !important;
}
body .highcharts-tracker path {
    outline: none;
}
body .highcharts-label {
    fill: #ffffff !important;
    stroke: none !important;
    text-shadow: 0 0 0 transparent;
}
body .highcharts-data-label > text,
body .highcharts-data-label .highcharts-text-outline {
    stroke-width: 0 !important;
    fill: #ffffff !important;
}
.flex.list > div {
    padding: 0 2em;
    border-left: solid 1px rgba(255,255,255,0.13);
    line-height: 1.7;
}
.flex.list > div:first-of-type {
    border: none;
    padding-left: 1em;
}
.flex.list strong {
    font-weight: 600;
    font-size: 1.1em;
    color: #ffffff;
}
a.btnMore,
button.btnMore {
    display: inline-block;
    padding: 6px 28px 6px 12px;
    border: solid 2px #ffffff;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    position: relative;
    text-decoration: none;
    color: #ffffff;
    font-size: 12px;
}
a.btnMore::before,
button.btnMore::before {
    width: 10px;
    height: 22px;
    content: '';
    position: absolute;
    background-color: #101730;
    top: 50%;
    right: -4px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
a.btnMore::after,
button.btnMore::after {
    width: 26px;
    height: 10px;
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../public/svg/btn-arrow-white.svg');
}
span.dp {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-color: #5c91bd;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
span.dp.fletter {
    background-color: #5c91bd;
    text-align: center;
    line-height: 48px;
    font-weight: 600;
    font-size: 1.3rem;
    color: #ffffff;
    text-transform: uppercase;
}
.leaderC {
    border-style: solid;
    border-width: 1px 0;
    border-color: rgba(255,255,255,0.13);
}
.leaderC .slick-slide {
    padding-top: 1em;
    padding-bottom: 1em;
    border-style: solid;
    border-width: 0 1px 0 0 !important;
    border-color: rgba(255,255,255,0.13);
    line-height: 0;
}

.pageWrap .highcharts-container,
.pageWrap .highcharts-container .highcharts-root  {
    max-width: 100%;
    height: auto !important;
    max-height: auto !important;
}
.pageWrap .highcharts-container .highcharts-grid path,
.pageWrap .highcharts-container .highcharts-axis path {
    stroke: rgba(255,255,255,0.13);
}
.pageWrap .highcharts-container .highcharts-axis-labels text,
.pageWrap .highcharts-container .highcharts-axis text {
    color: #c2c2c2 !important;
    fill: #c2c2c2 !important;
}

.dashTable {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: none;
    margin: 0;
}
.dashTable th,
.dashTable td {    
    color: #ffffff;
    padding: 7px 10px;
    text-align: center;
}
.dashTable tr th:first-child,
.dashTable tr td:first-child,
.dashTable tr th:last-child,
.dashTable tr td:last-child {
    text-align: left;
}
.dashTable tr th:first-child,
.dashTable tr td:first-child {
    padding-left: 1rem;
}
.dashTable th {
    border: none;
    font-size: 14px;
    font-weight: 600;
}
.dashTable td {
    border-style: solid;
    border-width: 0.5px 0 0;
    border-color: rgba(255,255,255,0.13);
    font-size: 12px;
}
.tableWrap {
    overflow: auto;
    scrollbar-color: #666666 transparent;
    scrollbar-width: thin;
}
.tableWrap::-webkit-scrollbar {
    width: 8px; 
    height: 8px;
}
.tableWrap::-webkit-scrollbar-thumb { 
    background: #666666;
}
.tableWrap::-webkit-scrollbar-track { 
    background: transparent;
}
.tableWrap.tc {
    max-height: 460px;
}
.pageContWrap {
    min-height: calc(100vh - 65px);
}
.backTxtBtn {
    position: relative;
    background-color: transparent;
    background-image: none;
    border: none;
    outline: none;
    padding: 0 0 0 30px;
    margin-bottom: 0.45em;
    font-size: 16px;
    font-weight: 700;
    color: #d0d0d0;
}
.backTxtBtn::before {
    content: '';
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../public/svg/back-arrow-light.svg');
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.backTxtBtn:hover {
    color: #ffffff;
}

.tableListWrap {    
    overflow: auto;
}
.tableListWrap .contTable {    
    font-size: 0.85rem;  
    max-height: calc(100vh - 140px);  
    position: relative;
}
.tableHead,
.tBodyRow {
    text-align: center;
}
.tBodyRow {
    margin-bottom: 0.5em;
    background-color: rgba(68,74,95,0.48);
}
.tableListWrap .contTable .tableHead > .flex-none.w140,
.tableListWrap .contTable .tBodyRow > .flex-none.w140 {
    width: 140px;
}
.tableListWrap .contTable .tableHead > .flex-none,
.tableListWrap .contTable .tBodyRow > .flex-none {
    width: 80px;
}
.tableListWrap .contTable .tableHead {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #101730;
}
span.badge {
    display: inline-block;
    padding: 4px 0;
    width: 36px;
    text-align: center;
    background: #101730;
    border: 1px solid #444A5F;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    font-weight: 700;
    color: #d0d0d0;
}
.tableListWrap .contTable .tBodyRow a {
    color: var(--primary);
    text-decoration: underline;
    font-weight: 700;
}
.tableListWrap .contTable .tBodyRow a:hover {
    text-decoration: none;
}
.tableListWrap .contTable .tBodyRow > div:not(:first-child) {
    border-left: solid 1px rgba(255,255,255,0.12);
}

.pageWrap.withCopy {
    padding-bottom: 50px;
    position: relative;
}
.pageWrap.withCopy .copyFloat {
    position: absolute;
    right: 1em;
    bottom: 1em;
}
.pageWrap .hScreen2 {
    position: relative;
    padding-bottom: 2rem;
}
.pageWrap .hScreen .copyFloat {
    position: absolute;
    right: 1em;
    bottom: 1rem;
}
.grayBox {
    background-color: #1B223B;
}
.lightWhite {
    background-color: #38405B;
}
.text-gray {
    color: #d0d0d0;
}
.linkBox a {
    display: block;
}
.linkBox a::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.linkBox span.ico {
    display: inline-block;
    width: 38px;
    height: 38px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.linkBox span.ico.pu-trans {
    background-image: url('../../../public/svg/punjabi-translation.svg');
}
.linkBox span.ico.machine-trans {
    background-image: url('../../../public/svg/machine-transit.svg');
}
.linkBox span.ico.doc {
    background-image: url('../../../public/svg/document.svg');
}
.linkBox span.ico.voice-recog {
    background-image: url('../../../public/svg/voice-recognition-line.svg');
}
.linkBox span.ico.speech {
    background-image: url('../../../public/svg/part-of-speech.svg');
}
.linkBox span.ico.upload {
    background-image: url('../../../public/svg/upload-white-icon.svg');
}
.linkBox span.ico.gurbani {
    background-image: url('../../../public/svg/gurbani.svg');
}

button.popClose {
    position: absolute;
    top: -22px;
    right: -34px;
    z-index: 9;
}
.popBody {
    max-height: calc(100vh - 230px);
    overflow-x: hidden;
    overflow-y: auto;
}
.popBody td.border {
    border-color: #9f9f9f;
}
.popBody thead th {
    padding: 0.5rem;
    border: solid 1px #9f9f9f;
    background-color: #f2f2f2;
}

.uplBoxOuter {
    width: 95%;
    max-width: 420px;
}
.uplBox {    
    border: dashed 1px #44A0DF;
    -webkit-border-radius: 12px;
    border-radius: 12px;  
    overflow: hidden;  
}
.uplBox > label {
    padding: 1.6rem;
    position: relative;
    display: block;
}
.uplBox > label > input {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.uplBox p span {
    display: inline-block;    
    text-decoration: underline;    
    color: #44A0DF;
}
.uplBox p.text-xs {
    color: #707070;
    font-size: 0.65rem;
}
.uplBoxOuter .text-sm {
    font-size: 0.75rem;
}
.uplBox > label > div {
    background-color: rgba(68, 152, 219, 0.95);
    z-index: 3;
}
.tableListWrap .contTable .tableHead.gray {
    background-color: #1B223B;
}
.tableListWrap .contTable.text-xs {
    font-size: 0.75rem;
}
.grayBox .tBodyRow {
    background-color: #272E47;
}
.grayBox .dBorder .tBodyRow {
    -webkit-box-shadow: inset 0 0 4px rgba(255,255,255,1);
    box-shadow: inset 0 0 4px rgba(255,255,255,1);
}
.grayBox .tableListWrap .contTable .tBodyRow > div:not(:first-child) {
    border: none;
}
.tBodyRow .btnView,
.tBodyRow .btnDel {
    display: inline-block;
    width: 38px;
    height: 38px;
    border: none;
    outline: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.tBodyRow .btnView {
    background-image: url('../../../public/svg/view-btn-ico.svg');
}
.tBodyRow .btnView.doc {
    background-image: url('../../../public/svg/view-btn-cate-ico.svg');
}
.tBodyRow .btnDel {
    background-image: url('../../../public/svg/delete-btn-ico.svg');
}
.grayBox .bdrBtm:not(:last-of-type) {
    border-bottom: solid 1px rgba(255,255,255,0.1);
}


@media all and (min-width: 992px) {
    .vDivider {
        position: relative;
    }
    .vDivider::after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #000000;
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
    }    
}
@media all and (min-width: 1024px) {
    .mainHdr .nav li {
        border-right: solid 1px rgba(55, 60, 77, 0.6);
    }

    .mainHdr .nav li:last-child {
        border-right: none;
    }

    .mainHdr .nav a {
        padding: 18px 14px;
    }

    .mainHdr .nav li.active a {
        border-width: 0 0 3px 0;
    }
}

@media all and (min-width: 1300px) {
    .mainHdr a {
        font-size: 95%;
    }
    .mainHdr .nav a {
        padding: 18px 24px;
    }
}

@media all and (max-width: 767px) {
    .pageWrap.withCopy .copyFloat {
        right: 0;
        width: 100%;
        text-align: center;
    }
    button.popClose {
        top: -34px;
        right: 0;
    }
}

@media all and (max-width: 480px) {
    .leaderC .slick-slide {
        border: none !important;
    }
}

@media all and (max-width: 991px) {    
    body button.clsPopBtn {
        right: 10px;
    }
    .vDivider .w-full:nth-of-type(2) {
        border-top: solid 1px #000000;
        padding-top: 2em;
    }

    body .grow.hScreen {
        min-height: auto;
    }
}


/* Some common styles */

.tableListWrap .contTable .tableHead.nonSticky {
    position: static;
}


.bbox,
.hbbox {
    position: relative;
    cursor: pointer;
    border: solid 1px #44A0DF;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    margin: 2px;
    outline: none;
}
.hbbox {
    background-color: rgba(68,160,223,0.2);
}
.hbbox button.clos-b {
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    width: 13px;
    height: 13px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../public/svg/bbox-close.svg');
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
    text-indent: -9999px;
}

.selectTag {
    padding: 0 4px;
    font-size: 0.85em;
    border-radius: 2px;
    color: #ffffff;
    background: #009285;
    margin: 0 2px;
}


select.selectTag {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 10px 0 2px;
    font-size: 0.85em;
    border-radius: 2px;
    color: #ffffff;
    background: #009285 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 10px center/6px 10px;
}

.grayBox mark {
    cursor: pointer;
    border: solid 1px #44A0DF;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    margin: 0 4px;
    background-color: transparent !important;
    color: #ffffff;
}
.grayBox mark > span {
    display: inline-block;
    vertical-align: middle;
    padding: 0 4px;
    font-size: 0.85em;
    border-radius: 2px;
    color: #ffffff;
    background: #009285;
    margin: 0 2px;
    transform: translateY(-1px);
}

.selectTag.xPad {
    padding: 4px 16px 4px 6px;
}

.pageWrap.withCopy.pbKB {
    padding-bottom: 230px;
}
.pageWrap.withCopy.pbKBImgTg {
    padding-bottom: 300px;
}
button.btnCloseKB {
    background-color: transparent;
    background-image: none;
    padding: 0;
    border: none;
    outline: none;
    color: #ffffff;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 38px;
    line-height: 0;
}


.boxBdr {
    border: solid 1px var(--bdrGray);
    -webkit-border-radius: 12px;
    border-radius: 12px;
}
.tableListWrap .tBodyRow.flex .flex-1 > p,
.tableListWrap .tBodyRow.flex .flex-1 > a {
    display: inline-block;    
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    margin: auto;
}
.tableListWrap .tBodyRow.flex .flex-1 > p.mw10 {
    max-width: 30px;    
    -webkit-line-clamp: 1;
    white-space: nowrap;
    display: inline-block;
}
button.clsPopBtn {
    background-color: transparent;
    background-image: none;
    border: none;
    outline: none;
    padding: 0;
    font-size: 2rem;
    color: #ffffff;
    position: absolute;
    z-index: 999;
    top: -2rem;
    right: -2rem;
    width: 32px;
    height: 32px;
}
.popTrnsBg {
    background-color: rgba(0,0,0,0.8);
}

.tagMgn {
    margin: 0 3px 4px;
}
.tagMgn .hbbox {
    margin: 0;
}

.tableWrap.tc tbody tr {
    position: relative;
}
.tableWrap.tc tbody tr td a {
    display: block;
}
.tableWrap.tc tbody tr td a::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.tableWrap.tc tbody tr.active {
    background-color: rgba(255,255,255,0.15);
}

.lStat > span {    
    display: inline-block;
    color: #ffffff;
    padding: 2px 6px;
    font-size: 14px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 12px;
    text-transform: capitalize;
}
.lStat > span[class*='ccept'],
.lStat > span[class*='view'],
.lStat > span[class*='load'] {
    background-color: #44A0DF;
}
.lStat > span[class*='end'],
.lStat > span[class*='cess'] {
    background-color: #B6BA04;
}
.lStat > span[class*='eje'] {
    background-color: #e46060;
}

.counterBox.h-auto {
    height: auto;
}

.hScreen {
    min-height: calc(100vh - 60px);
}
.bgLight2 {
    background-color: #38405B;
}

body input.error,
body select.error {
    border: solid 1px #e36060 !important;
}


body .authModBox input.error, 
body .authModBox select.error {
    border-width: 0 0 1px !important;
}


/* ===== Used for Category-Tags Tabs ========= */

.TabbedSection .react-tabs__tab-list {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 0;
    border-bottom-color: rgba(255,255,255,0.40);
}
.TabbedSection .react-tabs__tab-list .react-tabs__tab {
    padding: 0 0 10px;
    margin-right: 1.5rem;
}
.TabbedSection .react-tabs__tab-list .react-tabs__tab:nth-of-type(1) {
    margin-left: 4rem;
}
.TabbedSection .react-tabs__tab-list .react-tabs__tab:focus,
.TabbedSection .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
    background-color: transparent;
    font-weight: 600;
    border-style: solid;
    border-width: 0 0 4px;
    border-color: var(--primary);
    color: var(--primary);
}
.TabbedSection .react-tabs__tab-list .react-tabs__tab:focus::after {
    display: none;
}

.tableListWrap .contTable .tBodyRow .flex-none img[src$='.svg'] {
    max-width: 38px;
    margin: auto;
}

@media all and (min-width: 1024px) {
    .TabbedSection .react-tabs__tab-list {
        padding-left: 2.25rem;
        padding-right: 2.25rem;
    }
    .TabbedSection .react-tabs__tab-list .react-tabs__tab {
        margin-right: 4rem;
    }
    .TabbedSection .react-tabs__tab-list .react-tabs__tab:nth-of-type(1) {
        margin-left: 7rem;
    }
}

@media all and (max-width: 1380px) {
    .pageWrap .tableListWrap .tBodyRow.flex .flex-1 > p,
    .pageWrap .tableListWrap .tBodyRow.flex .flex-1 > a {
        max-width: 80px;
    }
    .pageWrap .tableListWrap .tBodyRow.flex .flex-1 > p.mw10 {
        max-width: 30px;
    }
}
