/* @font-face {
    font-family: 'anmollipiregular';
    src: url('./fonts/anmollipi-webfont.eot');
    src: url('./fonts/anmollipi-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/anmollipi-webfont.woff2') format('woff2'),
         url('./fonts/anmollipi-webfont.woff') format('woff'),
         url('./fonts/anmollipi-webfont.ttf') format('truetype'),
         url('./fonts/anmollipi-webfont.svg#anmollipiregular') format('svg');
    font-weight: normal;
    font-style: normal;
} */

/* @font-face {
    font-family: 'gurbaniakharregular';
    src: url('./fonts/gurbaniakhar-webfont.eot');
    src: url('./fonts/gurbaniakhar-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/gurbaniakhar-webfont.woff2') format('woff2'),
         url('./fonts/gurbaniakhar-webfont.woff') format('woff'),
         url('./fonts/gurbaniakhar-webfont.ttf') format('truetype'),
         url('./fonts/gurbaniakhar-webfont.svg#gurbaniakharregular') format('svg');
    font-weight: normal;
    font-style: normal;
} */

@font-face {
    font-family: 'Raavi';
    src: url('./fonts/Raavi.eot');
    src: url('./fonts/Raavi.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raavi.woff2') format('woff2'),
        url('./fonts/Raavi.woff') format('woff'),
        url('./fonts/Raavi.ttf') format('truetype'),
        url('./fonts/Raavi.svg#Raavi') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Asees';
    src: url('./fonts/Asees.eot');
    src: url('./fonts/Asees.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Asees.woff2') format('woff2'),
        url('./fonts/Asees.woff') format('woff'),
        url('./fonts/Asees.ttf') format('truetype'),
        url('./fonts/Asees.svg#Asees') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




.panjabiFont {
    font-family: 'Asees';
    font-weight: normal;
}
