.profIco,
.profLetter {
    width: 42px;
    height: 42px;
    background-color: #5c91bd;
    border: solid 2px #ffffff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.profIco {    
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../public/svg/default-prof-ico.svg');    
}

.profLetter > span {
   font-weight: 600;
   font-size: 1.3rem;
   color: #ffffff; 
   height: 100%;
   line-height: 39px;
   text-transform: uppercase;
}

.profAccWrap:hover > .navL {
    opacity: 1;
    visibility: visible;
}
.navL {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    background: #272E47;
    padding: 6px 0;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    min-width: 140px;
    position: absolute;
    z-index: 100;
    right: 0;
    top: calc(100% + 6px);
}
.navL > button,
.navL > a {
    background-color: transparent;
    background-image: none;
    padding: 8px 14px;
    margin: 0;
    border-style: solid;
    border-width: 1px 0;
    border-color: #444A5F;
    outline: none;
    color: #ffffff;
    width: 100%;
    display: block;
    text-align: center;
    font-size: 14px !important;
    letter-spacing: 0.06em;
}
.navL > a + button {
    margin-top: -1px;
}
.navL > button:hover,
.navL > a:hover {
    background-color: #101010;
}
.navL > a {
    border-top-width: 0;
}
.navL > button {
    border-bottom-width: 0;
}
.profAccWrap {
    position: relative;
}