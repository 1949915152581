/* === Stylesheet for Virtual Keyboard === */

.keyboardWrap {
    overflow: auto;
    max-height: 500px;
}
.keyboard {
    width: 694px;
    margin: auto;    
}
.keyboard button {
    background-color: #101730;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 42px;
    height: 40px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    position: relative;
    display: block;
    cursor: default;
}
/* .keyboard button:hover,
.keyboard button:focus {
    background: -webkit-linear-gradient(90deg, #44A0DF -5%, #456EC8 100%);
    background: linear-gradient(90deg, #44A0DF -5%, #456EC8 100%);
} */
.keyboard button::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.keyboard button.r1k14 {
    width: 91px;
}
.keyboard button.r2k1 {
    width: 68px;
}
.keyboard button.r2k14 {
    width: 65px;
}
.keyboard button.r3k1 {
    width: 81px;
}
.keyboard button.r3k14 {
    width: 83px;
}
.keyboard button.r3k14::before {
    background-size: 25px auto;
    background-position: right 5px bottom 4px;
}
.keyboard button.r4k1 {
    width: 82px;
}
.keyboard button.r4k12 {
    width: 102px;
}
.keyboard button.r5k1 {
    width: 56px;
}
.keyboard button.r5k2,
.keyboard button.r5k6 {
    width: 66px;
}
.keyboard button.r5k3,
.keyboard button.r5k5 {
    width: 66px;
}
.keyboard button.r5k4 {
    width: 226px;
}
.keyboard button.r5k7 {
    width: 98px;
}

/* Row 1 Keys */
.keyboard button.r1k1::before {
    background-image: url('../../public/keyboard/svg/row1/row1key1.svg');
}
.keyboard button.r1k2::before {
    background-image: url('../../public/keyboard/svg/row1/row1key2.svg');
}
.keyboard button.r1k3::before {
    background-image: url('../../public/keyboard/svg/row1/row1key3.svg');
}
.keyboard button.r1k4::before {
    background-image: url('../../public/keyboard/svg/row1/row1key4.svg');
}
.keyboard button.r1k5::before {
    background-image: url('../../public/keyboard/svg/row1/row1key5.svg');
}
.keyboard button.r1k6::before {
    background-image: url('../../public/keyboard/svg/row1/row1key6.svg');
}
.keyboard button.r1k7::before {
    background-image: url('../../public/keyboard/svg/row1/row1key7.svg');
}
.keyboard button.r1k8::before {
    background-image: url('../../public/keyboard/svg/row1/row1key8.svg');
}
.keyboard button.r1k9::before {
    background-image: url('../../public/keyboard/svg/row1/row1key9.svg');
}
.keyboard button.r1k10::before {
    background-image: url('../../public/keyboard/svg/row1/row1key10.svg');
}
.keyboard button.r1k11::before {
    background-image: url('../../public/keyboard/svg/row1/row1key11.svg');
}
.keyboard button.r1k12::before {
    background-image: url('../../public/keyboard/svg/row1/row1key12.svg');
}
.keyboard button.r1k13::before {
    background-image: url('../../public/keyboard/svg/row1/row1key13.svg');
}
.keyboard button.r1k14::before {
    background-image: url('../../public/keyboard/svg/row1/row1key14.svg');
}

/* Row 2 Keys */
.keyboard button.r2k1::before {
    background-image: url('../../public/keyboard/svg/row2/row2key1.svg');    
}
.keyboard button.r2k2::before {
    background-image: url('../../public/keyboard/svg/row2/row2key2.svg');
}
.keyboard button.r2k3::before {
    background-image: url('../../public/keyboard/svg/row2/row2key3.svg');
}
.keyboard button.r2k4::before {
    background-image: url('../../public/keyboard/svg/row2/row2key4.svg');
}
.keyboard button.r2k5::before {
    background-image: url('../../public/keyboard/svg/row2/row2key5.svg');
}
.keyboard button.r2k6::before {
    background-image: url('../../public/keyboard/svg/row2/row2key6.svg');
}
.keyboard button.r2k7::before {
    background-image: url('../../public/keyboard/svg/row2/row2key7.svg');
}
.keyboard button.r2k8::before {
    background-image: url('../../public/keyboard/svg/row2/row2key8.svg');
}
.keyboard button.r2k9::before {
    background-image: url('../../public/keyboard/svg/row2/row2key9.svg');
}
.keyboard button.r2k10::before {
    background-image: url('../../public/keyboard/svg/row2/row2key10.svg');
}
.keyboard button.r2k11::before {
    background-image: url('../../public/keyboard/svg/row2/row2key11.svg');
}
.keyboard button.r2k12::before {
    background-image: url('../../public/keyboard/svg/row2/row2key12.svg');
}
.keyboard button.r2k13::before {
    background-image: url('../../public/keyboard/svg/row2/row2key13.svg');
}
.keyboard button.r2k14::before {
    background-image: url('../../public/keyboard/svg/row2/row2key14.svg');
}

/* Row 3 Keys */
.keyboard button.r3k1::before {
    background-image: url('../../public/keyboard/svg/row3/row3key1.svg');
}
.keyboard button.r3k2::before {
    background-image: url('../../public/keyboard/svg/row3/row3key2.svg');
}
.keyboard button.r3k3::before {
    background-image: url('../../public/keyboard/svg/row3/row3key3.svg');
}
.keyboard button.r3k4::before {
    background-image: url('../../public/keyboard/svg/row3/row3key4.svg');
}
.keyboard button.r3k5::before {
    background-image: url('../../public/keyboard/svg/row3/row3key5.svg');
}
.keyboard button.r3k6::before {
    background-image: url('../../public/keyboard/svg/row3/row3key6.svg');
}
.keyboard button.r3k7::before {
    background-image: url('../../public/keyboard/svg/row3/row3key7.svg');
}
.keyboard button.r3k8::before {
    background-image: url('../../public/keyboard/svg/row3/row3key8.svg');
}
.keyboard button.r3k9::before {
    background-image: url('../../public/keyboard/svg/row3/row3key9.svg');
}
.keyboard button.r3k10::before {
    background-image: url('../../public/keyboard/svg/row3/row3key10.svg');
}
.keyboard button.r3k11::before {
    background-image: url('../../public/keyboard/svg/row3/row3key11.svg');
}
.keyboard button.r3k12::before {
    background-image: url('../../public/keyboard/svg/row3/row3key12.svg');
}
.keyboard button.r3k13::before {
    background-image: url('../../public/keyboard/svg/row3/row3key13.svg');
}
.keyboard button.r3k14::before {
    background-image: url('../../public/keyboard/svg/row3/row3key14.svg');
}

/* Row 4 Keys */
.keyboard button.r4k1::before {
    background-image: url('../../public/keyboard/svg/row4/row4key1.svg');
    left: 3px;
}
.keyboard button.r4k2::before {
    background-image: url('../../public/keyboard/svg/row4/row4key2.svg');
}
.keyboard button.r4k3::before {
    background-image: url('../../public/keyboard/svg/row4/row4key3.svg');
}
.keyboard button.r4k4::before {
    background-image: url('../../public/keyboard/svg/row4/row4key4.svg');
}
.keyboard button.r4k5::before {
    background-image: url('../../public/keyboard/svg/row4/row4key5.svg');
}
.keyboard button.r4k6::before {
    background-image: url('../../public/keyboard/svg/row4/row4key6.svg');
}
.keyboard button.r4k7::before {
    background-image: url('../../public/keyboard/svg/row4/row4key7.svg');
}
.keyboard button.r4k8::before {
    background-image: url('../../public/keyboard/svg/row4/row4key8.svg');
}
.keyboard button.r4k9::before {
    background-image: url('../../public/keyboard/svg/row4/row4key9.svg');
}
.keyboard button.r4k10::before {
    background-image: url('../../public/keyboard/svg/row4/row4key10.svg');
}
.keyboard button.r4k11::before {
    background-image: url('../../public/keyboard/svg/row4/row4key11.svg');
}
.keyboard button.r4k12::before {
    background-image: url('../../public/keyboard/svg/row4/row4key12.svg');
    left: -3px;
}

/* Row 5 Keys */
.keyboard button.r5k1::before {
    background-image: url('../../public/keyboard/svg/row5/row5key1.svg');
    left: 3px;
}
.keyboard button.r5k2::before {
    background-image: url('../../public/keyboard/svg/row5/row5key2.svg');
}
.keyboard button.r5k3::before {
    background-image: url('../../public/keyboard/svg/row5/row5key3.svg');
}
.keyboard button.r5k5::before {
    background-image: url('../../public/keyboard/svg/row5/row5key5.svg');
}
.keyboard button.r5k6::before {
    background-image: url('../../public/keyboard/svg/row5/row5key6.svg');
}
.keyboard button.r5k7::before {
    background-image: url('../../public/keyboard/svg/row5/row5key7.svg');
    /* left: -3px; */
}


@media all and (min-width: 1600px) {
    .keyboard {
        width: 902px;
        margin: auto;    
    }
    .keyboard button {
        width: 57px;
        height: 55px;
    }
    .keyboard button.r1k14 {
        width: 121px;
    }
    .keyboard button.r2k1 {
        width: 87px;
    }
    .keyboard button.r2k14 {
        width: 95px;
    }
    .keyboard button.r3k1 {
        width: 107px;
    }
    .keyboard button.r3k13 {
        width: 137px;
    }
    .keyboard button.r4k1 {
        width: 136px;
    }
    .keyboard button.r4k12 {
        width: 176px;
    }
    .keyboard button.r5k1 {
        width: 89px;
    }
    .keyboard button.r5k2,
    .keyboard button.r5k6 {
        width: 76px;
    }
    .keyboard button.r5k3,
    .keyboard button.r5k5 {
        width: 86px;
    }
    .keyboard button.r5k4 {
        width: 376px;
    }
    .keyboard button.r5k7 {
        width: 122px;
    }
}